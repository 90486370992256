import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppReportsService = {
  strings: localStrings,
  getPayouts: function() {
    return AppApiService.get('getPayouts');
  },
  getPayoutById: function(id) {
    return AppApiService.post('getPayoutById', null, id);
  },
  getPayoutCsvById: function(id) {
    return AppApiService.post('getPayoutCsvById', null, id);
  }
};

export default AppReportsService;
