import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  SubscriptionAccess,
  RoleCanEdit,
} from '../../../../../../OperatorShared';

//servies
import AppIncomeService from '../../AppIncomeService';
import AppPlatformService from '../../../../../../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../../../../../../services/AppApi/AppApiService';

// //components
import Header from '../components/Header';
import Section from '../components/Section';
import { EnterpriseList, IncomeList } from '../components';

//global components
import { LoadingSpinner } from '../../../../../../../shared';
import CsoNotFound from '../../../../../../../shared/AppNotFound/CsoNotFound';

//tools
import { downloadMultiCSVReport, downloadString } from '../../../../../../../shared/Download/download';

//context
import { ErrorContext } from '../../../../../../../context/shared/ErrorContext';
import { CsoAccountContext } from '../../../../../../../context/operator/getCsoAccount';

//styles
import { StatementContainerStyle } from '../../../../../../Statements/StatementStyles';
import { DivStyles, TextStyles } from '../../../../../../../SharedStyles';

//helpers
import { timeConvert } from '../../../../../../../shared/Helpers';

const PayoutView = (props) => {
  const { strings } = AppIncomeService;
  const { account } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [payout, setPayout] = useState(null);
  const [hasKiosk, setHasKiosk] = useState(false);

  useEffect(() => {
    const fetchPayoutId = async () => {
      try {
        const res = await AppIncomeService.getPayoutById(props.match.params.id);
        setPayout(res);
        const settings = await AppApiService.get('getCSOAccountSettings');
        setHasKiosk(settings.hasKiosk);
        setLoading(false);
      } catch (err) {
        setError({
          display: true,
          title: 'Something went wrong',
          message: err.message,
          styles: 'cso',
        });
        setLoading(false);
      }
    };
    fetchPayoutId();
  }, [props.match.params.id, setError]);

  if (!payout && !loading) {
    return <CsoNotFound />;
  }

  const downloadPayoutReport = () => {
    //TODO: export to CSV
    async function callAsync() {
      var x = await AppIncomeService.getPayoutCsvById(props.match.params.id);
      downloadString([x],
        `${account.csoAccountName}_Payout_${moment(payout.date).format(
          'MM/DD/YYYY'
        )}`
      );
    }
    callAsync();    
    return;
/*
    let payoutSessions = [];
    payout.transactions.forEach((t) => {
      let transactionFormat = {};
      transactionFormat.date = moment(t.transactionDate).format('MM/DD/YYYY');
      transactionFormat.startTime = t.time;
      transactionFormat.chargerID = t.iotHubDeviceId;
      transactionFormat.service = t.entryType;
      transactionFormat.avgkW = t.avgkW;
      transactionFormat.kWhs = t.totalkWh;
      transactionFormat.rateType = t.rateType;
      transactionFormat.rate = `${AppPlatformService.currencyFormat(t.rate)}`;
      transactionFormat.amt = `${AppPlatformService.currencyFormat(t.totalCost)}`;
      transactionFormat.time = timeConvert(t.totalTime);

      payoutSessions.push(transactionFormat);
    });

    //payout information - id/date
    let payoutInfomation = {
      title: 'Payout Information',
      data: [
        {
          id: payout.id,
          accountName: account.csoAccountName,
          payoutDate: payout.payoutDate,
        },
      ],
    };
    // earnedIncome Summaries
    let payoutSummaries = {
      title: 'Earned Income Summary',
      data: [
        {
          sessions: payout.transactions.length,
          averagekW: payout.averagekW.toFixed(2),
          totalkWhs: payout.totalkWhs.toFixed(2),
          chargeAmt: `${AppPlatformService.currencyFormat(payout.totalChargeAmount)}`,
          idleFees: `${AppPlatformService.currencyFormat(payout.totalIdleFees)}`,
          reservationFees: `${AppPlatformService.currencyFormat(payout.totalReservationFees)}`,
        },
      ],
    };
    // earnedIncome
    let payoutTransactions = {
      title: 'Earned Income Sessions',
      voidFormat: true,
      data: payoutSessions,
    };
    //other fees
    let payoutOtherFees = {
      title: 'Other Fees',
      data: [
        {
          bankFee: `$${payout.totalBankFees}`,
          transferFee: `$${payout.totalTransferFees}`,
        },
      ],
    };
    //total payout
    let totalPayout = {
      title: 'Total Payout',
      data: [
        {
          postDate: payout.stripeTransaction.date,
          bankName: payout.stripeTransaction.bankName,
          bankRoutingNo: payout.stripeTransaction.routingNumber,
          transID: payout.stripeTransaction.transactionId,
          amount: `${AppPlatformService.currencyFormat(payout.stripeTransaction.amount)}`,
        },
      ],
    };

    downloadMultiCSVReport(
      `${account.csoAccountName}_Payout_${moment(payout.date).format(
        'MM/DD/YYYY'
      )}`,
      [
        payoutInfomation,
        payoutSummaries,
        payoutTransactions,
        payoutOtherFees,
        totalPayout,
      ]
    );*/
  };

  const AccuredPayouts = (props) => {
    return (
      <>
        {props.payouts.map(payout => (
          <>
            <h2>{strings.previousAcctivityInThisPerriod}</h2>          
            <Section key={payout.id}>
                {moment(payout.payoutDate).format('MM/DD/YYYY')}&nbsp;
                {strings.sessionTitle} $
                {AppPlatformService.currencyFormat((payout.totalEarnings))}
                &nbsp;<Link
                  to={`/reports/payouts/${
                    payout.id !== undefined ? payout.id : payout.payoutId
                  }`}>
                  {strings.view}
                </Link>
            </Section>
          </>
        ))}
      </>
    );
  }

  return (
    <OuterWrapper title={strings.payoutView} internal path='/reports/payouts'>
      {loading && <LoadingSpinner />}
      <RoleCanEdit roles={[1, 2]}>
        {!loading && (
          <ComponentWrapper title={strings.payoutView}>
            <Header
              strings={strings}
              account={account}
              payout={payout}
              download={downloadPayoutReport}
            />
            <StatementContainerStyle minHeight='580px' width='100%'>
              <h2>
                {strings.sessionTitle} 
                {AppPlatformService.currencyFormat((payout.totalEarnings))} 
              </h2>
              {/* Charging Session Income - with Transactions */}
              <Section>
              {strings.sessions}: {payout.transactions.length};{' '}
                {strings.avgKw}:{' '}
                {payout.averagekW !== 'NaN'
                  ? payout.averagekW.toFixed(2)
                  : payout.averagekW}
                ; {strings.totKwhs}: {payout.totalkWhs.toFixed(2)};{' '}
                {strings.chargeAmt}: {AppPlatformService.currencyFormat(payout.totalChargeAmount)};{' '}
                <SubscriptionAccess levels={[3]}>
                  {strings.idleFees}: {AppPlatformService.currencyFormat(payout.totalIdleFees)};{' '}
                  {strings.resFees}: {AppPlatformService.currencyFormat(payout.totalReservationFees)}
                </SubscriptionAccess>
              </Section>

              <SubscriptionAccess levels={[0, 1, 2, 3]}>
                <EnterpriseList statementDetails={payout} strings={strings} />
              </SubscriptionAccess>

              {payout.accuredPayouts && <AccuredPayouts payouts = {payout.accuredPayouts}/>}

              {/* Ampedup transfer/bank fees */}
              {(payout.totalBankFees + payout.totalTransferFees) !== 0 && <>
                <h2>
                  {strings.otherFeesTitle}: 
                  {AppPlatformService.currencyFormat((payout.totalBankFees + payout.totalTransferFees))}
                </h2>

                <DivStyles padding='15px 10px' borderBottom>
                  <DivStyles display='flex' alignItems='center'>
                    <TextStyles
                      size='14px'
                      margin='0 0 5px'
                      color='#8f8d8d'
                      flex='1'
                      maxWidth='200px'>
                      <strong>{'Bank Fee'}:</strong> 
                      {AppPlatformService.currencyFormat(payout.totalBankFees)}
                    </TextStyles>
                  </DivStyles>
                  <DivStyles>
                    <TextStyles
                      size='14px'
                      margin='0'
                      color='#8f8d8d'
                      flex='1'
                      maxWidth='200px'>
                      <strong>{'Transfer Fee'}:</strong> 
                      {AppPlatformService.currencyFormat(payout.totalTransferFees)}
                    </TextStyles>
                  </DivStyles>
                  {hasKiosk && (
                  <DivStyles>
                    <TextStyles
                      size='14px'
                      margin='0'
                      color='#8f8d8d'
                      flex='1'
                      maxWidth='200px'>
                      NOTE: Bank <em>Fees and Transfer Fees</em> are <strong>NOT</strong> assessed on transactions generated by payment kiosks. Those fees are assessed and separately reported via the <a href='https://merchant.ampedup.net/'>payment kiosk merchant portal</a>. 
                    </TextStyles>
                  </DivStyles>
                  )}
                </DivStyles>
              </>}

              {/* Ampedup Payout/transfer/debit to connect account */}
              {payout.totalPayout !== 0 && <>
                <h2>
                  {strings.incomeTitle} {AppPlatformService.currencyFormat(payout.totalPayout)}
                </h2>

                <Section>
                  Earned Income: 
                  {AppPlatformService.currencyFormat((payout.totalChargeAmount + payout.totalIdleFees))} -
                  Other Fees: 
                  {AppPlatformService.currencyFormat((payout.totalBankFees + payout.totalTransferFees))} =
                  Payout Amt: {AppPlatformService.currencyFormat(payout.totalPayout)}
                </Section>
                {/* Credits to payout on the Payout View Page */}
                <IncomeList
                  credit
                  statementDetails={payout.stripeTransaction}
                  strings={strings}
                />
              </>}
            </StatementContainerStyle>
          </ComponentWrapper>
        )}
      </RoleCanEdit>
    </OuterWrapper>
  );
};

export default PayoutView;
