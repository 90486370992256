/**
 * Function will take in reverse, status, and an array, this will return a newly sorted array based on the reverse and status criteria
 * This is used on pages with pagination and the array will be used in a useEffect when the reverse order changes and array will be passed to the *onChangePage* function from pagination
 * @param {*} reverse - reverse object with the values true or false
 * @param {*} status - the item that should be sorted by reversed
 * @param {*} array - the array itself
 */
export const handleSortReversePagination = (reverse, status, array) => {
  const sortCompareWithReverse = (a, b) => {
    //custom sort reverse for statements
    if (a[status] === undefined || b[status] === undefined) {
      if (status === 'amount') {
        if (!reverse[status]) {
          return (
            a.totalPayoutAmount -
            a.totalSubscriptionFees -
            (b.totalPayoutAmount - b.totalSubscriptionFees)
          );
        } else {
          return (
            b.totalPayoutAmount -
            b.totalSubscriptionFees -
            (a.totalPayoutAmount - a.totalSubscriptionFees)
          );
        }
      }
      if (status === 'activityPeriod') {
        if (!reverse[status]) {
          return new Date(a.startDate) - new Date(b.startDate);
        } else {
          return new Date(b.startDate) - new Date(a.startDate);
        }
      }
    }

    if (typeof a[status] !== 'number' || typeof b[status] !== 'number') {
      if (!reverse[status]) {
        return String(a[status])
          .toString()
          .toLowerCase()
          .localeCompare(String(b[status]).toString().toLowerCase());
      } else {
        return String(b[status])
          .toString()
          .toLowerCase()
          .localeCompare(String(a[status]).toString().toLowerCase());
      }
    }

    if (!reverse[status]) {
      return a[status] - b[status];
    } else {
      return b[status] - a[status];
    }
  };
  let sortedArray = array.sort(sortCompareWithReverse);

  return sortedArray;
};
