import React, { useState, useEffect } from 'react';

//service
import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';

//components
import LedgerHistory from '../../../../LedgerHistory/LedgerHistory';

//hooks
import { useVehiclesLedger } from '../../../../hooks/useLedgerHook';

//styles
import {
  LedgerDataGraphContainerStyles,
  LedgerDataGraphStyles,
  LedgerListStyles,
  LedgerFilterSelectStyles,
  LedgerSelectContainerStyles,
} from '../../../../../DriverStyles';
import {
  TextStyles,
  ListPlaceholderStyles,
  DivStyles,
  TitleStyles,
} from '../../../../../SharedStyles';

const HistoryContainer = (props) => {
  const { strings, driver } = props;
  const [vehicle, setVehicle] = useVehiclesLedger(props.vehicle);
  const [chargingHistory, setChargingHistory] = useState(null);

  useEffect(() => {
    setVehicle(props.vehicle);
    // eslint-disable-next-line
  }, [props.vehicle]);

  useEffect(() => {
    setChargingHistory(vehicle.chargingHistory);
  }, [vehicle]);

  //filters
  const [filteredTransaction, setFilteredTransaction] = useState([]);
  const [filter, setFilter] = useState({
    month: '',
    year: '',
    vehicleId: '',
  });
  const [utilityCost, setUtilityCost] = useState(0);
  const [miles, setMiles] = useState(0); //total calc for miles on filtered search history
  const [kiloWatts, setKiloWatts] = useState(0); //total calc for kwh on filtered search history
  const [totalCost, setTotalCost] = useState(0); //total calc for cost on filtered search history

  //History =======================================================================

  //looping over the chargingHistory Data for the vehicle - if Check for charging history
  let monthDate =
    chargingHistory &&
    chargingHistory.map((data) => {
      const date = new Date(data.ledgerDate);
      const month = date.toLocaleDateString(
        driver.language ? driver.language : 'en-US',
        { month: 'long' }
      );
      return month;
    });
  //getting the years from charging session history
  let yearDate =
    chargingHistory &&
    chargingHistory.map((data) => {
      let singleDate = data.ledgerDate;
      let brokenUpDate = singleDate.split('-');
      return brokenUpDate[0];
    });
  //remove duplicate months
  let uniqueMonths = [...new Set(monthDate)];
  //remove duplicate years
  let uniqueYears = [...new Set(yearDate)];

  //getting Month out to compare function
  const getMonth = (date) => {
    const dateMonth = new Date(date);
    const monthConvert = dateMonth.toLocaleDateString(
      driver.language ? driver.language : 'en-US',
      {
        month: 'long',
      }
    );
    return monthConvert;
  };
  //getting Year out to compare function
  const getYear = (date) => {
    let singleDate = date;
    let brokenUpDate = singleDate.split('-');
    return brokenUpDate[0];
  };
  //declaring the history cost, watts, and miles

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (chargingHistory) {
      setUtilityCost(0);
      setMiles(0);
      setKiloWatts(0);
      setTotalCost(0);
      let filteredArray =
        chargingHistory &&
        chargingHistory
          .filter((item) => {
            item.month = getMonth(item.ledgerDate);
            if (filter.month !== '') {
              return item.month === filter.month;
            } else {
              return item;
            }
          })
          .filter((item) => {
            item.year = getYear(item.ledgerDate);
            if (filter.year !== '') {
              return item.year === filter.year;
            } else {
              return item;
            }
          })
          .map((h) => {
            if (h.rateType === 'Owned') {
              setUtilityCost((prev) => prev + h.amount);
            } else {
              setTotalCost((prev) => prev + h.amount);
            }
            setMiles((prev) => {
              return prev + h.miles;
            });
            setKiloWatts((prev) => prev + h.totalPower);
            return h;
          });
      setFilteredTransaction(filteredArray);
    }
    // eslint-disable-next-line
  }, [chargingHistory, filter, driver.unitOfMeasurement]);

  //looping over the charginghistory
  const chargingHistoryData = filteredTransaction.map((history, index) => {
    return <LedgerHistory {...history} key={index} sessionHistory />;
  });

  // object which holds the order value of the month
  var monthNames = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  //sorting function to the filter months
  uniqueMonths.sort(function (a, b) {
    // sort based on the value in the monthNames object
    return monthNames[a] - monthNames[b];
  });

  return (
    <DivStyles border>
      <TitleStyles
        align='center'
        background='#e7e7e7'
        size='14px'
        weight='600'
        padding='7px 0'>
        {strings.titleHistory}
      </TitleStyles>
      <LedgerSelectContainerStyles>
        <LedgerFilterSelectStyles name='month' onChange={handleFilterChange}>
          <option value=''>Month</option>
          {uniqueMonths.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </LedgerFilterSelectStyles>
        <LedgerFilterSelectStyles name='year' onChange={handleFilterChange}>
          <option value=''>Year</option>
          {uniqueYears.map((year) => {
            return (
              <option value={year} key={year}>
                {year}
              </option>
            );
          })}
        </LedgerFilterSelectStyles>
      </LedgerSelectContainerStyles>
      <LedgerDataGraphContainerStyles>
        <LedgerDataGraphStyles>
          <TextStyles size='13px'>
            {driver.unitOfMeasurement === 'Miles'
              ? strings.titleMiles
              : strings.distanceUnitKm}
          </TextStyles>
          <TextStyles size='14px' weight='600'>
            {driver.unitOfMeasurement === 'Miles'
              ? miles.toFixed(0)
              : (miles*1.60934).toFixed(0)} 
          </TextStyles>
        </LedgerDataGraphStyles>
        <LedgerDataGraphStyles>
          <TextStyles size='13px'>{strings.distanceUnitPower}</TextStyles>
          <TextStyles size='14px' weight='600'>
            {kiloWatts.toFixed(2)}
          </TextStyles>
        </LedgerDataGraphStyles>
        <LedgerDataGraphStyles>
          <TextStyles size='13px'>{strings.titleCost}</TextStyles>
          <TextStyles size='14px' weight='600'>
            {AppPlatformService.currencyFormat(totalCost)}
          </TextStyles>
        </LedgerDataGraphStyles>
        <LedgerDataGraphStyles>
          <TextStyles size='13px'>{strings.titleUtility}</TextStyles>
          <TextStyles size='14px' weight='600'>
            {AppPlatformService.currencyFormat(utilityCost)}
          </TextStyles>
        </LedgerDataGraphStyles>
      </LedgerDataGraphContainerStyles>

      <LedgerListStyles height='380px'>
        {chargingHistoryData.length !== 0 ? (
          chargingHistoryData
        ) : (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noLedger}</p>
          </ListPlaceholderStyles>
        )}
      </LedgerListStyles>
    </DivStyles>
  );
};

export default HistoryContainer;
