import React, { useContext, useState } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppChargersModalService from './AppChargersModalService';

//global components
import { LoadingSpinner } from '../../../shared';
import ModalButton from '../../../shared/Buttons/ModalButton';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  SelectStyles,
} from '../../../SharedStyles';

const PricingScheduleModal = (props) => {
  const { strings } = AppChargersModalService;
  const { account } = useContext(CsoAccountContext);
  const [loading, setLoading] = useState(false);
  const [selectedPriceSchedule, setSelectedPriceSchedule] = useState(null);

  const [errorMessage, setErrorMessage] = useState({
    display: false,
    message: '',
  });

  const closeModal = () => {
    props.handleCloseModal();
  };

  const handleChange = (e) => {
    let options = e.target.options;
    const selectedPriceSchedules = [...options]
      .filter((option) => {
        return option.selected;
      })
      .map((selectedOption) => {
        return selectedOption.value;
      });
    setSelectedPriceSchedule(selectedPriceSchedules.length > 0 ? selectedPriceSchedules[0] : null);
  };

  const handleSaveInfo = async (e) => {
    setLoading(true);
    try {
      //add multiple chargers membership
      if (props.addPricingSchedule) {
        let iotHubDeviceIds = props.chargers.map((charger) => {
          return charger.iotHubDeviceId;
        });
        let response = await AppChargersModalService.chargerToPricingSchedule(
          {
            iotHubDeviceIds: iotHubDeviceIds,
            priceScheduleId: selectedPriceSchedule,
          }
        );
        if (response) {
          closeModal();
        }
      }
    } catch (err) {
      if (err.response) {
        setErrorMessage({ display: true, message: err.response.data });
      }
    }
  };

  if (props.addPricingSchedule) {
    return (
      <ModalStyles>
        {loading && <LoadingSpinner />}
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.addPricingScheduleTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            {errorMessage.display && (
              <ModalItemStyles padding='0 10px'>
                <p>ERROR: {errorMessage.message}</p>
              </ModalItemStyles>
            )}
            <ModalItemStyles padding='0 10px'>
              <p>
                {strings.addPricingScheduleContentOne} {props.chargers.length}{' '}
                {strings.addPricingScheduleContentTwo}
              </p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <SelectStyles
                noDropdown
                onChange={handleChange}
                size={account.pricingSchedules.length}
                name='membershipIds'>
                {account.pricingSchedules.map((profile) => {
                  return (
                    <option key={profile.id} value={profile.id}>
                      {profile.name}
                    </option>
                  );
                })}
              </SelectStyles>
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            disabled={
              props.chargers.length === 0 || !selectedPriceSchedule
            }
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={handleSaveInfo}
            buttonText={strings.assignPricingSchedule}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

};

export default PricingScheduleModal;
