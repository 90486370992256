import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

//services
import AppChargersService from '../AppChargersService';

//components
import ChargersCard from './ChargersCard';
import ChargerCheckbox from './ChargerCheckbox/ChargerCheckbox';

//global components
import SubscriptionAccess from '../../OperatorShared/SubscriptionAccess';
import Pagination from '../../../shared/Pagination/Pagination';

import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import EnterpriseAdmin from '../../EnterpriseAdmin';

//hooks
import { usePagination } from '../../../shared/hooks/usePagination';
import { useRouter } from '../../../shared/hooks/useRouter';

//modals
import MembershipModal from '../ChargersModals/MembershipModal';
import CouponModal from '../ChargersModals/CouponModal';
import Delete from '../ChargersForm/ChargerEditProps/EditModals/Delete';
import PricingScheduleModal from '../ChargersModals/PricingScheduleModal';

//images
import chargerPlaceholderImage from '../../../../assets/images/ChargingStationPlaceholder400.png';

//context
import { CsoMembershipContext } from '../../../context/operator/getCsoMembership';

//styles
import {
  DivStyles,
  FormStyles,
  SelectRoundStyles,
  ButtonGreyStyles,
  ListPlaceholderStyles,
} from '../../../SharedStyles';

//helpers
import { actionFilterEnum } from '../helpers';
import { ErrorContext } from '../../../context/shared/ErrorContext';
import { downloadCSV } from '../../../shared/Download/download';

const ChargersList = (props) => {
  const { strings } = AppChargersService;
  const { allMemberships } = useContext(CsoMembershipContext);
  const { setError } = useContext(ErrorContext);

  const router = useRouter();

  const [chargersList, setChargersList] = useState([]);
  const [loading, setLoading] = useState(true);
  //charger action state
  const [allSelectedChargers, setAllSelectedChargers] = useState([]);
  const [actionRequest, setActionRequest] = useState({
    actionValue: null,
    membershipDisplay: false,
    removeMembershipDisplay: false,
    couponDisplay: false,
    removeCouponDisplay: false,
    deleteDisplay: false,
  });
  const [deleteModal, setDeleteModal] = useState(false);

  const handleLoading = (status) => {
    setLoading(status);
  };

  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  //fetches data without any params or query Strings
  const fetchingData = async (filterOptions) => {
    setChargersList([]);
    setLoading(true);
    let chargers = await AppChargersService.getChargers(filterOptions);
    chargers.map((res) => {
      res.selected = false;
      return res;
    });
    setChargersList(chargers);
    if (chargers.length === 0) {
      setLoading(false);
    }
  };
  const query = new URLSearchParams(router.location.search);

  //fetches data with a query Strings based on modal from locations page for meter groups
  const fetchQueryData = async (filterOptions) => {
    setChargersList([]);
    setLoading(true);
    let locationId = query.get('locationId');
    let meterGroupId = query.get('meterGroupId');
    let membershipId = query.get('membershipId');
    let pricingScheduleId = query.get('pricingScheduleId');
    let chargerStatus = query.get('filteredStatus');
    if (locationId) {
      let allQueriedChargers = await AppChargersService.getChargers(
        filterOptions
      );
      let filtered = allQueriedChargers.filter((charger) => {
        return charger.csoLocationId === locationId;
      });
      setChargersList(filtered);
      setLoading(false);
    }
    if (meterGroupId) {
      let allQueriedChargers = await AppChargersService.getChargers(
        filterOptions
      );
      let filtered = allQueriedChargers.filter((charger) => {
        return charger.meterGroupId === meterGroupId;
      });
      setChargersList(filtered);
      setLoading(false);
    }
    if (membershipId) {
      let allQueriedChargers = await AppChargersService.getChargers(
        filterOptions
      );
      let filterMembership = allMemberships
        .filter((membership) => {
          return membership.id === membershipId;
        })
        .map((stationIds) => {
          return allQueriedChargers.filter((charger) => {
            return stationIds.chargingStationIds.includes(
              charger.iotHubDeviceId
            );
          });
        });

      setChargersList(filterMembership.flat());
      setLoading(false);
    }
    if (pricingScheduleId) {
      let allQueriedChargers = await AppChargersService.getChargers(
        filterOptions
      );
      let filtered = allQueriedChargers.filter((charger) => {
        return charger.pricingScheduleId === pricingScheduleId;
      });
      setChargersList(filtered);
      setLoading(false);
    }    
    if (chargerStatus) {
      let allQueriedChargers = await AppChargersService.getChargers(
        filterOptions
      );
      if (chargerStatus === 'In-use/Charging') {
        chargerStatus = 'Charging';
      }
      let filtered = allQueriedChargers.filter((charger) => {
        return charger.connectorsStatus.filter(e => e.status === chargerStatus).length > 0;
      });
      setChargersList(filtered);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!router.location.search) {
      fetchingData(props.searchedChargers);
    } else {
      fetchQueryData(props.searchedChargers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.search, props.searchedChargers]);

  const checkAllChargers = (e) => {
    if (e.target.checked) {
      let selectChargers = currentData()
        .map((charger) => {
          if (charger.hasBooted) {
            charger.selected = true;
          }
          return charger;
        })
        .filter((charger) => {
          return charger.selected === true;
        });
      setAllSelectedChargers([...allSelectedChargers, ...selectChargers]);
    } else {
      let removeSelected = allSelectedChargers.filter((charger) => {
        if (charger.hasBooted) {
          charger.selected = false;
        }
        return charger.selected !== false;
      });
      setAllSelectedChargers(removeSelected);
    }
  };

  const checkSelectedCharger = (id) => {
    chargersList.forEach((charger) => {
      if (id === charger.iotHubDeviceId) {
        charger.selected = !charger.selected;
        if (charger.selected) {
          setAllSelectedChargers([...allSelectedChargers, charger]);
        } else {
          let removeSelected = allSelectedChargers.filter((charger) => {
            return charger.selected !== false;
          });
          setAllSelectedChargers(removeSelected);
        }
      }
    });
  };

  const handleActionSubmit = async (e) => {
    e.preventDefault();
    //todo set action with the value for the go button click
    //assign membership
    if (actionRequest.actionValue === 0) {
      setActionRequest({
        ...actionRequest,
        membershipDisplay: true,
      });
    }
    //remove membership
    if (actionRequest.actionValue === 1) {
      setActionRequest({
        ...actionRequest,
        removeMembershipDisplay: true,
      });
    }
    //assign coupon
    if (actionRequest.actionValue === 2) {
      setActionRequest({
        ...actionRequest,
        couponDisplay: true,
      });
    }
    //remove coupon
    if (actionRequest.actionValue === 3) {
      setActionRequest({
        ...actionRequest,
        removeCouponDisplay: true,
      });
    }
    if (actionRequest.actionValue === 4) {
      if (allSelectedChargers.length > 0) {
        let stations = allSelectedChargers.map((charger) => {
          return charger.iotHubDeviceId;
        });
        let data = {
          enable: false,
          chargingStationIds: stations,
        };
        try {
          await AppChargersService.toggleChargersEnable(data);
        } catch (err) {
          setError({
            display: true,
            title: 'Phase Type Mismatch',
            message:
              "The phase type of this charger does not match the selected phase type of the associated Meter Group. Change the Meter Group entry to correspond with this model's phase type.",
            styles: 'cso',
          });
        }
      }
    }
    if (actionRequest.actionValue === 5) {
      if (allSelectedChargers.length > 0) {
        let stations = allSelectedChargers.map((charger) => {
          return charger.iotHubDeviceId;
        });
        let data = {
          enable: true,
          chargingStationIds: stations,
        };
        try {
          await AppChargersService.toggleChargersEnable(data);
        } catch (err) {
          setError({
            display: true,
            title: 'Phase Type Mismatch',
            message:
              "The phase type of this charger does not match the selected phase type of the associated Meter Group. Change the Meter Group entry to correspond with this model's phase type.",
            styles: 'cso',
          });
        }
      }
    }
    if (actionRequest.actionValue === 6) {
      if (allSelectedChargers.length > 0) {
        handleDeleteModal();
      }
    }  
    if (actionRequest.actionValue === 7) { //Assign Pricing Schedule
      setActionRequest({
        ...actionRequest,
        assignPricingScheduleDisplay: true,
      });
    }
    if (actionRequest.actionValue === 8) { //Remove Pricing Schedule
      setActionRequest({
        ...actionRequest,
        removePricingScheduleDisplay: true,
      });
    }
  };

  //work in progress
  const handleActionChange = (e) => {
    let data = e.target.value;
    setActionRequest({
      actionValue: parseInt(data),
    });
  };

  const handleCloseModal = () => {
    setActionRequest({
      ...actionRequest,
      membershipDisplay: false,
      removeMembershipDisplay: false,
      couponDisplay: false,
      removeCouponDisplay: false,
      deleteDisplay: false,
      assignPricingScheduleDisplay: false,
      removePricingScheduleDisplay: false,
    });
  };

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(chargersList, 20);

  useEffect(() => {
    if (currentData().length) {
      setLoading(false);
    }
  }, [currentData]);

  const handleDownload = () => {
    //Location, Charger ID, Max kW, Ports (number of ports), Connecter Type, Enabled (true/false), Status (available, connected, charging, in use, offline, trouble)
    const reportData = chargersList.map((charger) => {
      return {
        'Location': charger.chargerAddress,
        'Charger ID': charger.iotHubDeviceId,
        //'Charger Name': charger.name,
        'Max kW': charger.power,
        'Ports': charger.connectorsStatus.length,
        'Connector Type': charger.connectorsStatus.map((conn) => conn.connectorType).join(' / '),
        'Enabled': charger.enabledSubscription,
      };
    });
    downloadCSV(reportData, 'chargers');
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {chargersList.length !== 0 && (
        <Pagination
          currentCount={currentCount}
          totalData={totalData}
          next={next}
          prev={prev}
          jump={jump}
          currentPage={currentPage}
          maxPage={maxPage}
          pageNumbers={pageNumbers}
        />
      )}
      {deleteModal && (
        <Delete
          massDelete
          updateChargers={fetchingData}
          chargers={allSelectedChargers}
          handleModalClose={handleDeleteModal}
        />
      )}
      {actionRequest.membershipDisplay && (
        <MembershipModal
          handleCloseModal={handleCloseModal}
          addMembership
          chargers={allSelectedChargers}
        />
      )}
      {actionRequest.removeMembershipDisplay && (
        <MembershipModal
          handleCloseModal={handleCloseModal}
          removeMembership
          chargers={allSelectedChargers}
        />
      )}
      {actionRequest.couponDisplay && (
        <CouponModal
          handleCloseModal={handleCloseModal}
          addCoupon
          handleLoading={handleLoading}
          chargers={allSelectedChargers}
        />
      )}
      {actionRequest.removeCouponDisplay && (
        <CouponModal
          handleCloseModal={handleCloseModal}
          removeCoupon
          handleLoading={handleLoading}
          chargers={allSelectedChargers}
        />
      )}
      {actionRequest.assignPricingScheduleDisplay && (
        <PricingScheduleModal
          handleCloseModal={handleCloseModal}
          addPricingSchedule
          chargers={allSelectedChargers}
        />
      )}     
      <EnterpriseAdmin noMessage>
        <DivStyles
          padding='10px'
          mobilePadding='10px 5px'
          borderBottom
          display='flex'
          alignItems='center'>
          <ChargerCheckbox handleChange={checkAllChargers} />
          <FormStyles id='actionBar' onSubmit={handleActionSubmit}>
            <SelectRoundStyles
              bgPosition='.85em'
              size='14px'
              padding='4px 12px'
              name='actions'
              onChange={handleActionChange}>
              <option>{strings.actionFilterPlaceholder}</option>
              {strings.actionFilters.map((action) => {
                return (
                  <option value={actionFilterEnum[action]} key={action}>
                    {action}
                  </option>
                );
              })}
            </SelectRoundStyles>
            <ButtonGreyStyles
              margin='0 10px'
              padding='2px 10px'
              form='actionBar'
              type='submit'>
              {strings.actionButton}
            </ButtonGreyStyles>
          </FormStyles>
          <SubscriptionAccess levels={[3]}>
            <ButtonGreyStyles
              disabled={ chargersList.length === 0 }
              margin='0 10px 0 auto'
              padding='2px 10px'
              onClick={handleDownload}>
              {strings.export}
            </ButtonGreyStyles>  
          </SubscriptionAccess>        
        </DivStyles>
      </EnterpriseAdmin>
      <DivStyles minHeight='500px' padding='0'>
        {currentData().map((charger, i) => (
          <ChargersCard
            index={i}
            charger={charger}
            updateChargers={fetchingData}
            key={charger.iotHubDeviceId}
            checkSelectedCharger={checkSelectedCharger}
          />
        ))}
        {chargersList.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noChargersAdded}</p>
            <img src={chargerPlaceholderImage} alt='No Charger' />
          </ListPlaceholderStyles>
        )}
      </DivStyles>
    </>
  );
};

export default withRouter(ChargersList);
