import AppNotificationsService from './AppNotificationsService';

const { strings } = AppNotificationsService;

export const adminNotificationSettings = [
  {
    name: 'customerSupportRequests',
    label: strings.optionCustomerSupportRequests,
  },
  {
    name: 'statementGenerated',
    label: strings.optionBankStatements,
  },
  {
    name: 'payoutGenerated',
    label: strings.optionIncomePayouts,
  },
  {
    name: 'approveNewMembership',
    label: strings.optionApproveNewMembership,
  },
  {
    name: 'approveEVDriverChargerImage',
    label: strings.optionApproveEVDriverImage,
  },
  {
    name: 'approveEVDriverChargerComments',
    label: strings.optionApproveEVDriverChargerComments,
  },
  {
    name: 'driverIdleFeeNotice',
    label: strings.optionDriverIdleFeeNotices,
  },
  {
    name: 'firmwareUpdateAvailable',
    label: strings.optionFirmwareUpdate,
  },
  {
    name: 'chargerOfflineAlert',
    label: strings.optionOffline,
  },
  {
    name: 'chargerErrorAlert',
    label: strings.optionError,
  },
  {
    name: 'chargerThresholdAlert',
    label: strings.optionThreshold,
  },
  {
    name: 'meterGroupOfflineAlert',
    label: strings.optionMeterGroupOffline,
  },
  {
    name: 'meterGroupThresholdAlert',
    label: strings.optionMeterGroupThreshold,
  },
  {
    name: 'newSystemUserAccount',
    label: strings.optionNewSystemUser,
  },
  {
    name: 'newMembershipNotice',
    label: strings.optionNewMembershipNotice,
  },
  {
    name: 'chargingStartedCSONotify',
    label: strings.chargingStarted,
  },

];

export const managerNotificationSettings = [
  {
    name: 'customerSupportRequests',
    label: strings.optionCustomerSupportRequests,
  },
  {
    name: 'statementGenerated',
    label: strings.optionBankStatements,
  },
  {
    name: 'payoutGenerated',
    label: strings.optionIncomePayouts,
  },
  {
    name: 'approveNewMembership',
    label: strings.optionApproveNewMembership,
  },
  {
    name: 'approveEVDriverChargerImage',
    label: strings.optionApproveEVDriverImage,
  },
  {
    name: 'approveEVDriverChargerComments',
    label: strings.optionApproveEVDriverChargerComments,
  },
  {
    name: 'driverIdleFeeNotice',
    label: strings.optionDriverIdleFeeNotices,
  },
  {
    name: 'firmwareUpdateAvailable',
    label: strings.optionFirmwareUpdate,
  },
  {
    name: 'chargerOfflineAlert',
    label: strings.optionOffline,
  },
  {
    name: 'chargerErrorAlert',
    label: strings.optionError,
  },
  {
    name: 'chargerThresholdAlert',
    label: strings.optionThreshold,
  },
  {
    name: 'meterGroupOfflineAlert',
    label: strings.optionMeterGroupOffline,
  },
  {
    name: 'meterGroupThresholdAlert',
    label: strings.optionMeterGroupThreshold,
  },
  {
    name: 'chargingStartedCSONotify',
    label: strings.chargingStarted,
  },
];

export const techNotificationSettings = [
  {
    name: 'firmwareUpdateAvailable',
    label: strings.optionFirmwareUpdate,
  },
  {
    name: 'chargerOfflineAlert',
    label: strings.optionOffline,
  },
  {
    name: 'chargerErrorAlert',
    label: strings.optionError,
  },
  {
    name: 'chargerThresholdAlert',
    label: strings.optionThreshold,
  },
  {
    name: 'meterGroupOfflineAlert',
    label: strings.optionMeterGroupOffline,
  },
  {
    name: 'meterGroupThresholdAlert',
    label: strings.optionMeterGroupThreshold,
  },
];

export const customerNotificationSettings = [
  {
    name: 'customerSupportRequests',
    label: strings.optionCustomerSupportRequests,
  },
  {
    name: 'approveNewMembership',
    label: strings.optionApproveNewMembership,
  },
  {
    name: 'approveEVDriverChargerImage',
    label: strings.optionApproveEVDriverImage,
  },
  {
    name: 'approveEVDriverChargerComments',
    label: strings.optionApproveEVDriverChargerComments,
  },
  {
    name: 'driverIdleFeeNotice',
    label: strings.optionDriverIdleFeeNotices,
  },
];

export const smallBizNotificationSettings = [
  {
    name: 'customerSupportRequests',
    label: strings.optionCustomerSupportRequests,
  },
  {
    name: 'statementGenerated',
    label: strings.optionBankStatements,
  },
  {
    name: 'approveNewMembership',
    label: strings.optionApproveNewMembership,
  },
  {
    name: 'approveEVDriverChargerImage',
    label: strings.optionApproveEVDriverImage,
  },
  {
    name: 'approveEVDriverChargerComments',
    label: strings.optionApproveEVDriverChargerComments,
  },
  {
    name: 'driverIdleFeeNotice',
    label: strings.optionDriverIdleFeeNotices,
  },
  {
    name: 'firmwareUpdateAvailable',
    label: strings.optionFirmwareUpdate,
  },
  {
    name: 'chargerOfflineAlert',
    label: strings.optionOffline,
  },
  {
    name: 'chargerErrorAlert',
    label: strings.optionError,
  },
  {
    name: 'chargerThresholdAlert',
    label: strings.optionThreshold,
  },
  {
    name: 'chargingStartedCSONotify',
    label: strings.chargingStarted,
  },
];

export const sharedAccountNotificationSettings = [
    {
    name: 'approveNewMembership',
    label: strings.optionApproveNewMembership,
  },
  {
    name: 'statementGenerated',
    label: strings.optionBankStatements,
  },
  {
    name: 'firmwareUpdateAvailable',
    label: strings.optionFirmwareUpdate,
  },
  {
    name: 'chargerOfflineAlert',
    label: strings.optionOffline,
  },
  {
    name: 'chargerErrorAlert',
    label: strings.optionError,
  },
  {
    name: 'chargerThresholdAlert',
    label: strings.optionThreshold,
  },
  {
    name: 'chargingStartedCSONotify',
    label: strings.chargingStarted,
  },
];

export const nonSubAccountNotificationSettings = [
  {
    name: 'firmwareUpdateAvailable',
    label: strings.optionFirmwareUpdate,
  },
  {
    name: 'chargerOfflineAlert',
    label: strings.optionOffline,
  },
  {
    name: 'chargerErrorAlert',
    label: strings.optionError,
  },
  {
    name: 'chargerThresholdAlert',
    label: strings.optionThreshold,
  },
  {
    name: 'chargingStartedCSONotify',
    label: strings.chargingStarted,
  },
];
